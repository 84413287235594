.Travel {
  /* text-align: center; */
  background-color: #faf9f6;
}

.Title-style {
  background-color: #faf9f6;
  font-family: didot, serif;
  font-size: calc(5px + 2.2vmin);
  color: #6082b6;
  margin-top: 0vh;
  margin-bottom: 0vh;
  padding-top: 5vh;
  padding-bottom: 2vh;
  font-weight: bold;
  /* font-style: italic; */
}

.Accommodations_h1 {
  color: #6082b6;
  /* min-height: 15vh; */
  font-family: didot, serif;
  font-size: calc(5px + 2.5vmin);
  background-color: #faf9f6;
  font-weight: bold;
  margin-top: 0vh;
  margin-bottom: 0vh;
  padding-top: 8vh;
  padding-bottom: 0vh;
}

.Accommodations_h2 {
  color: #6082b6;
  /* min-height: 15vh; */
  font-family: didot, serif;
  font-size: calc(3px + 1.5vmin);
  background-color: #faf9f6;
  margin-top: 0vh;
  margin-bottom: 0vh;
  padding-top: 3vh;
  padding-bottom: 0vh;
  font-weight: bold;
  line-height: 3vh;
  /* text-align: center; */
}

.Accomodations_title {
  color: #6082b6;
  font-family: didot, serif;
  font-size: calc(3px + 2vmin);
  background-color: #faf9f6;
  padding-bottom: 5vh;
  /* text-decoration: none; */
}

.Airplane_h3 {
  color: #6082b6;
  font-family: didot, serif;
  font-size: calc(3px + 1.5vmin);
  background-color: #faf9f6;
  padding-bottom: 0vh;
  margin-left: 3vh;
  margin-right: 3vh;
  padding-top: 3vh;
}

.Accommodations_h3 {
  color: #6082b6;
  font-family: didot, serif;
  font-size: calc(3px + 1.5vmin);
  background-color: #faf9f6;
  padding-bottom: 0vh;
  margin-left: 5vh;
  margin-right: 5vh;
  /* text-align: left;
  padding-left: 60vh; */
}

.travel_picture {
  /* margin: 0px auto; */
  /* width: 600px; */
  /* max-width: 600px; */
  padding: 15px;
  width: 50%;
  /* width: auto;
  height: auto; */
}

@media screen and (max-width: 800px) {
  .travel_picture {
    /* margin: 0px auto; */
    /* width: 600px; */
    /* max-width: 600px; */
    padding: 15px;
    width: 80%;
    /* width: auto;
  height: auto; */
  }
  .Accommodations_h3 {
    color: #6082b6;
    font-family: didot, serif;
    font-size: calc(3px + 2.2vmin);
    background-color: #faf9f6;
    padding-bottom: 0vh;
    padding-right: 1vh;
    padding-left: 1vh;
    /* text-align: left;
    padding-left: 60vh; */
  }
  .Accommodations_h2 {
    color: #6082b6;
    /* min-height: 15vh; */
    font-family: didot, serif;
    font-size: calc(3px + 2.2vmin);
    background-color: #faf9f6;
    margin-top: 0vh;
    margin-bottom: 0vh;
    padding-top: 3vh;
    padding-bottom: 0vh;
    padding-right: 1vh;
    padding-left: 1vh;
    font-weight: bold;
    line-height: 3vh;
    /* text-align: center; */
  }
  .Accomodations_title {
    color: #6082b6;
    font-family: didot, serif;
    font-size: calc(3px + 2.7vmin);
    background-color: #faf9f6;
    padding-bottom: 5vh;
    /* text-decoration: none; */
  }
}
