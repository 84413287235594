.Travel {
  text-align: center;
  background-color: #faf9f6;
}

.Success {
  color: #6082b6;
  font-family: didot, serif;
  font-size: calc(4px + 2vmin);
  background-color: #faf9f6;
  margin-top: 0vh;
  margin-bottom: 0vh;
  padding-top: 4vh;
  padding-bottom: 4vh;
}

.Calendar {
  text-align: center;
  font-family: didot, serif;
  font-size: calc(4px + 2.5vmin);
}
