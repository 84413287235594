.App {
  text-align: center;
  background-color: #faf9f6;
  /* min-width: 800px; */
}

.App-NavBar {
  background-color: #4f77b4;
  min-height: 5vh;
  min-width: 0vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 50px;
  padding-right: 50px;

  font-size: calc(0.7px + 1.5vmin);
  font-family: didot, serif;
  color: #f7f8f8;
}

.App-body {
  color: #6082b6;
  min-height: 3vh;
  font-family: didot, serif;
  font-size: calc(7px + 3.5vmin);
  background-color: #faf9f6;
  font-weight: bold;
  margin-top: 0vh;
  padding-top: 5vh;
  padding-bottom: 10vh;
}

.App-placeholder {
  color: #000000;
  min-height: 75vh;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
}
.App-bodytextfancy {
  color: #6082b6;
  min-height: 1000 vh;
  font-family: didot, serif;
  font-size: calc(8px + 1.7vmin);
  background-color: #faf9f6;
  font-style: italic;
}

.App-bodytext {
  color: #6082b6;
  min-height: 1000 vh;
  font-family: didot, serif;
  font-size: calc(3px + 1.9vmin);
  background-color: #faf9f6;
  margin-top: 10px;
  margin-bottom: 25px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 45px;
  padding-right: 45px;
}

.details-h1 {
  color: #6082b6;
  /* min-height: 0vh; */
  font-family: didot, serif;
  font-size: calc(7px + 3.2vmin);
  background-color: #faf9f6;
  font-weight: bold;
  margin-top: 0vh;
  padding-top: 0vh;
  padding-bottom: 3vh;
}

.link-text {
  min-height: 1000 vh;
  text-decoration: none;
  font-size: calc(8px + 1.8vmin);
  font-weight: bold;
  color: #6082b6;
  font-family: didot, serif;
  margin-bottom: 20px;
  padding-top: 500px;
}

.App-bodytext2 {
  color: #6082b6;
  min-height: 1000 vh;
  font-family: didot, serif;
  font-size: calc(7px + 2vmin);
  background-color: #faf9f6;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  padding-bottom: 20px;
}
.App-link {
  color: #6082b6;
}

.App-photo {
  margin: 0px auto;
  /* max-width: 900px; */
  min-width: 800px;
  padding-top: 10px;
  /* height: auto; */
  /* position: absolute; */
  width: auto;
  height: 10;
  flex: 0.5;
}

.Photo_main {
  margin-right: 0.5vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
