.Header {
  background-color: #faf9f6;
  /* height: 20vh; */
  /*display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;*/
  /* display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center; */
  /*margin-top: 14px;*/
  /* margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px; */

  /* font-size: calc(1px + 1.8vmin); 
  font-family: didot, serif; */
  color: #6082b6;
  text-decoration: none;
}

.HeaderMain {
  font-size: calc(8px + 3.5vmin);
  font-family: DIDOT, serif;
  /*font-style: italic;*/
  font-weight: bold;
  text-decoration: none;
  /* margin-left: 0px;
  margin-right: 0px; */
  /* padding-left: 50px;
  padding-right: 50px; */
  padding-top: 100px;
  padding-bottom: 75px;
}

.Headerbelow {
  font-size: calc(3px + 1.5vmin);
  font-family: DIDOT, serif;
  /* font-style: italic; */
  /* font-weight: bold; */
  text-decoration: none;
}

@media screen and (max-width: 1025px) {
  .HeaderMain {
    font-size: calc(8px + 3.2vmin);
    font-family: DIDOT, serif;
    /*font-style: italic;*/
    font-weight: bold;
    text-decoration: none;
    /* margin-left: 0px;
    margin-right: 0px; */
    /* padding-left: 50px;
    padding-right: 50px; */
    padding-top: 75px;
    padding-bottom: 30px;
  }
}
