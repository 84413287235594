.OurStory {
  text-align: center;
  background-color: #faf9f6;
}

.Story_body {
  margin-top: 1vh;
}

.Story_h1 {
  color: #6082b6;
  /* min-height: 3vh; */
  font-family: didot, serif;
  font-size: calc(5px + 2.8vmin);
  background-color: #faf9f6;
  font-weight: bold;
  margin-top: 5vh;
  margin-bottom: 5vh;
  padding-top: 1vh;
  padding-bottom: 0vh;
  font-weight: bold;
  /* font-style: italic; */
}

.Story_h2 {
  color: #6082b6;
  min-height: 15vh;
  font-family: didot, serif;
  font-size: calc(3px + 1.6vmin);
  background-color: #faf9f6;
  /* margin-top: 0vh;
  margin-bottom: 0vh;
  padding-left: 35vh;
  padding-right: 35vh; */
  padding-top: 5vh;
  padding-bottom: 5vh;
  margin-left: 20vh;
  margin-right: 20vh;
  /* font-weight: bold; */
  /* font-style: italic; */
  /* display: flex; */
  /* align-items: center; */
  /* margin: 10px auto 25px; */
  /* max-width: 700px; */
}

.Story_photo_landscape {
  /* margin: 0px auto;
  width: 400px;
  /* max-width: 400px; */
  padding: 5px;
  width: 40%;
  /* width: auto;
  height: auto; */
  /* display: block;
  align-items: center; */
}

.Story_photo_portrait {
  /* margin: 0px auto;
  width: 400px;
  /* max-width: 400px; */
  padding: 5px;
  width: 20%;
  /* width: auto;
  height: auto; */
  /* display: block;
  align-items: center; */
}

@media screen and (max-width: 850px) {
  .Story_photo_landscape {
    /* margin: 0px auto;
    width: 400px;
    /* max-width: 400px; */
    padding: 5px;
    width: 80%;
    /* width: auto;
    height: auto; */
    /* display: block;
    align-items: center; */
  }
}

@media screen and (max-width: 850px) {
  .Story_photo_portrait {
    /* margin: 0px auto;
    width: 400px;
    /* max-width: 400px; */
    padding: 5px;
    width: 40%;
    /* width: auto;
    height: auto; */
    /* display: block;
    align-items: center; */
  }
}

@media screen and (max-width: 800px) {
  .Story_h2 {
    color: #6082b6;
    min-height: 15vh;
    font-family: didot, serif;
    font-size: calc(3px + 2vmin);
    background-color: #faf9f6;
    /* margin-top: 0vh;
  margin-bottom: 0vh;
  padding-left: 35vh;
  padding-right: 35vh; */
    padding-top: 5vh;
    padding-bottom: 5vh;
    margin-left: 3vh;
    margin-right: 3vh;
  }
}
