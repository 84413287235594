.Registry {
  text-align: center;
  background-color: #faf9f6;
}

.Title-style {
  background-color: #faf9f6;
  font-family: didot, serif;
  font-size: calc(5px + 2.7vmin);
  color: #6082b6;
  margin-top: 0vh;
  margin-bottom: 0vh;
  padding-top: 5vh;
  padding-bottom: 2vh;
  font-weight: bold;
  /* font-style: italic; */
}

.registry_h1_link {
  color: #6082b6;
  /* min-height: 10vh; */
  font-family: didot, serif;
  font-size: calc(5px + 1.5vmin);
  background-color: #faf9f6;
  margin-top: 5vh;
  margin-bottom: 0vh;
  padding-top: 0vh;
  padding-bottom: 0vh;
  padding-left: 30vh;
  padding-right: 30vh;
  /* text-decoration: none; */
}

.registry_h1 {
  color: #6082b6;
  /* min-height: 10vh; */
  font-family: didot, serif;
  font-size: calc(5px + 1.5vmin);
  background-color: #faf9f6;
  margin-top: 5vh;
  margin-bottom: 0vh;
  padding-top: 0vh;
  padding-bottom: 0vh;
  padding-left: 30vh;
  padding-right: 30vh;
}

.registry_h2 {
  color: #6082b6;
  /* min-height: 10vh; */
  font-family: didot, serif;
  font-size: calc(5px + 1.5vmin);
  background-color: #faf9f6;
  margin-top: 0vh;
  margin-bottom: 0vh;
  padding-top: 0vh;
  padding-bottom: 10vh;
  padding-left: 25vh;
  padding-right: 25vh;
  /* width: auto; */
}

.registry_photo {
  /* margin: 0px auto;
  width: 600px;
  max-width: 600px; */
  padding: 15px;
  width: 50%;
  /* width: auto;
  height: auto; */
}

@media screen and (max-width: 800px) {
  .registry_photo {
    /* margin: 0px auto; */
    /* width: 600px; */
    /* max-width: 600px; */
    padding: 15px;
    width: 80%;
    /* width: auto;
  height: auto; */
  }
}

@media screen and (max-width: 800px) {
  .registry_h1 {
    color: #6082b6;
    /* min-height: 10vh; */
    font-family: didot, serif;
    font-size: calc(5px + 1.5vmin);
    background-color: #faf9f6;
    margin-top: 10vh;
    margin-bottom: 0vh;
    padding-top: 0vh;
    padding-bottom: 5vh;
    padding-left: 2vh;
    padding-right: 2vh;
    /* width: auto; */
  }

  .registry_h1_link {
    color: #6082b6;
    /* min-height: 10vh; */
    font-family: didot, serif;
    font-size: calc(5px + 1.5vmin);
    background-color: #faf9f6;
    margin-top: 10vh;
    margin-bottom: 0vh;
    padding-top: 0vh;
    padding-bottom: 5vh;
    padding-left: 2vh;
    padding-right: 2vh;
    /* text-decoration: none; */
  }

  .registry_h2 {
    color: #6082b6;
    /* min-height: 10vh; */
    font-family: didot, serif;
    font-size: calc(5px + 1.5vmin);
    background-color: #faf9f6;
    margin-top: 0vh;
    margin-bottom: 0vh;
    padding-top: 0vh;
    padding-bottom: 10vh;
    padding-left: 2vh;
    padding-right: 2vh;
    /* width: auto; */
  }
}
