.Travel {
  text-align: center;
  background-color: #faf9f6;
}

.Title-style {
  background-color: #faf9f6;
  font-family: didot, serif;
  font-size: calc(5px + 2.2vmin);
  color: #6082b6;
  margin-top: 0vh;
  margin-bottom: 0vh;
  padding-top: 5vh;
  padding-bottom: 3vh;
  font-weight: bold;
  /* font-style: italic; */
}

.h1 {
  background-color: #faf9f6;
  /* min-height: 75vh; */
}

.rsvp_text {
  color: #6082b6;
  /* min-height: 5vh; */
  font-family: didot, serif;
  font-size: calc(3px + 2.5vmin);
  padding-top: 2vh;
  padding-bottom: 0vh;
}

.h2 {
  color: #6082b6;
  /* min-height: 5vh; */
  font-family: didot, serif;
  font-size: calc(3px + 1.5vmin);
  /* background-color: rgb(0, 0, 0, 0.05); */
  /* font-weight: bold; */
  /* margin-top: 3vh;
  margin-bottom: 0vh; */
  padding-top: 0vh;
  padding-bottom: 0vh;
  font-style: italic;
  padding-left: 1vh;
  padding-right: 1vh;

  margin: auto;
  /* max-width: 800px; */
  border-radius: 20px;
}

.h3 {
  color: #6082b6;
  /* min-height: 8vh; */
  font-family: didot, serif;
  font-size: calc(3px + 1.7vmin);
  margin-top: 0vh;
  margin-bottom: 0vh;
  padding-top: 0vh;
  padding-bottom: 0vh;
  font-weight: bold;
  text-align: center;
}

.box_h1 {
  color: #6082b6;
  /* background-color: rgb(0, 0, 0, 0.05); */
  /* padding: 60px 32px; */
  /* padding-left: 60px; */
  margin: auto;
  padding-top: 2vh;
  padding-left: 1vh;
  padding-right: 1vh;
  /* margin-top: 2vh;
  margin-bottom: 50vh; */
  /* max-width: 800px; */
  border-radius: 20px;
  text-align: center;
}

button {
  color: #6082b6;
  font-family: didot, serif;
  font-size: calc(3px + 1.7vmin);
  font-weight: bold;
  margin-top: 2vh;
  margin-bottom: 50vh;
  /* min-height: 4vh; */
}

.loading_h2 {
  text-align: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.error_h2 {
  text-align: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
  margin-left: 10vh;
  margin-right: 10vh;
}

.table_h2 {
  font-family: didot, serif;
  font-size: calc(3px + 2vmin);
  line-height: 10vh;
  color: #6082b6;
  /* font-style: italic; */
}

.rsvp_picture {
  /* margin: 0px auto;
  width: 400px;
  max-width: 400px; */
  padding: 15px;
  width: 30%;
  /* width: auto;
  height: auto; */
}

@media screen and (max-width: 500px) {
  .rsvp_picture {
    /* margin: 0px auto;
    width: 400px;
    max-width: 400px; */
    padding: 15px;
    width: 80%;
    /* width: auto;
    height: auto; */
  }
  .error_h2 {
    text-align: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: 0vh;
    margin-right: 0vh;
  }
}
