.Footer {
  background-color: #6082b6;
  height: 35vh;
  /*display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;*/
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /*margin-top: 14px;*/
  /* margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px; */
  /* font-size: calc(1.5px + 2.5vmin); */
  font-family: didot, serif;
  color: #faf9f6;
}

.Footer_body {
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 70px;
  padding-bottom: 30px;
  font-size: calc(1.5px + 1.9vmin);
  font-family: didot, serif;
  color: #faf9f6;
  text-decoration: none;
}

.Footer_email {
  /* margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 70px;
  padding-bottom: 30px; */
  font-size: calc(1.5px + 1.9vmin);
  font-family: didot, serif;
  color: #faf9f6;
  text-decoration: underline;
}

.Footer_Monogram {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
  /*margin-top: 14px;*/
  font-size: calc(3px + 5.5vmin);
  font-family: didot, serif;
}

.Footer_Date {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 5px;
  padding-bottom: 30px;
  font-size: calc(1.5px + 1.9vmin);
  font-family: didot, serif;
  margin-top: 0px;
  margin-bottom: 0px;
}

@media screen and (max-width: 500px) {
  .Footer_body {
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 70px;
    padding-bottom: 30px;
    font-size: calc(3px + 2.2vmin);
    font-family: didot, serif;
    color: #faf9f6;
    text-decoration: none;
  }
  .Footer_Monogram {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
    /*margin-top: 14px;*/
    font-size: calc(4px + 7vmin);
    font-family: didot, serif;
  }
  .Footer_Date {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    padding-bottom: 30px;
    font-size: calc(3px + 2.2vmin);
    font-family: didot, serif;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .Footer_email {
    /* margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 70px;
    padding-bottom: 30px; */
    font-size: calc(3px + 2.2vmin);
    font-family: didot, serif;
    color: #faf9f6;
    text-decoration: underline;
  }
}
