.Timeline {
  text-align: center;
  background-color: #faf9f6;
  color: #6082b6;
  font-family: didot, serif;
}

.Title-style {
  background-color: #faf9f6;
  font-family: didot, serif;
  font-size: calc(5px + 2.7vmin);
  color: #6082b6;
  margin-top: 0vh;
  margin-bottom: 0vh;
  padding-top: 5vh;
  padding-bottom: 2vh;
  font-weight: bold;
  /* font-style: italic; */
}

.TimelineHeader {
  font-size: calc(1.5px + 1.9vmin);
  font-family: didot, serif;
  color: #6082b6;
  background-color: #faf9f6;
  min-height: 4vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 30px;
  padding-right: 30px;
}

.Timeline_override {
  /* font-size: calc(2px + 1.8svmin); */
  font-family: didot, serif;
  color: #6082b6;
  /* background-color: #faf9f6; */
  /* align-items: center; */
  font-weight: bold;
}

.TimelineDot {
  color: #6082b6;
  background-color: #faf9f6;
}

.TimelineConnector {
  color: #6082b6;
  background-color: #faf9f6;
}

.Timeline-logo {
  height: 40vmin;
  pointer-events: none;
}

.Timeline-photo {
  /* margin: 0px auto;
  width: 400px;
  max-width: 400px; */
  padding: 15px;
  width: 50%;
  /* width: auto;
  height: auto; */
}

@media screen and (max-width: 500px) {
  .Timeline-photo {
    /* margin: 0px auto; */
    /* width: 600px; */
    /* max-width: 600px; */
    padding: 15px;
    width: 80%;
    /* width: auto;
  height: auto; */
  }
}
