.FAQ {
  text-align: center;
  background-color: #faf9f6;
}

.Title-style {
  background-color: #faf9f6;
  font-family: didot, serif;
  font-size: calc(5px + 2.2vmin);
  color: #6082b6;
  margin-top: 0vh;
  margin-bottom: 0vh;
  padding-top: 5vh;
  padding-bottom: 3vh;
  font-weight: bold;
  /* font-style: italic; */
}

.FAQ_h1 {
  color: #6082b6;
  /* min-height: 5vh; */
  font-family: didot, serif;
  font-size: calc(5px + 1.7vmin);
  background-color: #faf9f6;
  margin-top: 5vh;
  margin-bottom: 5vh;
  padding-top: 0vh;
  padding-bottom: 0vh;
}
/* @media screen and (max-width: 400px) {
  .FAQ_h2 {
    color: #6082b6;
    min-height: 10vh;
    font-family: didot, serif;
    font-size: calc(3px + 1.5vmin);
    background-color: #faf9f6;
    margin-top: 0vh;
    margin-bottom: 0vh;
    padding-top: 0vh;
    padding-bottom: 0vh;
    font-style: italic;
    padding-left: 0vh;
    padding-right: 0vh;
  }
} */

.FAQ_h2 {
  color: #6082b6;
  /* min-height: 10vh; */
  font-family: didot, serif;
  font-size: calc(3px + 1.7vmin);
  background-color: #faf9f6;
  margin-top: 0vh;
  margin-bottom: 0vh;
  padding-top: 0vh;
  padding-bottom: 0vh;
  font-style: italic;
  padding-left: 50vh;
  padding-right: 50vh;
  line-height: 4vh;
}

/* @media screen and (max-width: 400px) {
  .FAQ_h3 {
    color: #6082b6;
    min-height: 10vh;
    font-family: didot, serif;
    font-size: calc(3px + 1.5vmin);
    background-color: #faf9f6;
    margin-top: 0vh;
    margin-bottom: 0vh;
    padding-top: 0vh;
    padding-bottom: 10vh;
    font-style: italic;
    padding-left: 0vh;
    padding-right: 0vh;
  }
} */
.FAQ_h3 {
  color: #6082b6;
  min-height: 10vh;
  font-family: didot, serif;
  font-size: calc(3px + 1.5vmin);
  background-color: #faf9f6;
  margin-top: 0vh;
  margin-bottom: 0vh;
  padding-top: 0vh;
  padding-bottom: 10vh;
  font-style: italic;
  padding-left: 5vh;
  padding-right: 5vh;
}

.faq_photo {
  /* margin: 0px auto; */
  /* width: 600px;
  max-width: 600px; */
  padding: 15px;
  width: 50%;
  /* width: auto; */
  /* height: auto; */
}

@media screen and (max-width: 1025px) {
  .faq_photo {
    /* margin: 0px auto; */
    /* width: 600px; */
    /* max-width: 600px; */
    padding: 15px;
    width: 80%;
    /* width: auto;
  height: auto; */
  }
  .FAQ_h1 {
    color: #6082b6;
    /* min-height: 5vh; */
    font-family: didot, serif;
    font-size: calc(5px + 2.2vmin);
    background-color: #faf9f6;
    margin-top: 5vh;
    margin-bottom: 5vh;
    padding-top: 0vh;
    padding-bottom: 0vh;
    padding-left: 1vh;
    padding-right: 1vh;
  }
  .FAQ_h2 {
    color: #6082b6;
    /* min-height: 10vh; */
    font-family: didot, serif;
    font-size: calc(5px + 1.5vmin);
    background-color: #faf9f6;
    margin-top: 0vh;
    margin-bottom: 0vh;
    padding-top: 0vh;
    padding-bottom: 0vh;
    font-style: italic;
    padding-left: 1vh;
    padding-right: 1vh;
    line-height: 4vh;
  }
}

@media screen and (min-width: 1026px) {
  .FAQ_h2 {
    color: #6082b6;
    /* min-height: 10vh; */
    font-family: didot, serif;
    font-size: calc(3px + 1.5vmin);
    background-color: #faf9f6;
    margin-top: 0vh;
    margin-bottom: 0vh;
    padding-top: 0vh;
    padding-bottom: 0vh;
    font-style: italic;
    padding-left: 20vh;
    padding-right: 20vh;
    line-height: 4vh;
  }
}

@media screen and (min-width: 1500px) {
  .FAQ_h2 {
    color: #6082b6;
    /* min-height: 10vh; */
    font-family: didot, serif;
    font-size: calc(3px + 1.7vmin);
    background-color: #faf9f6;
    margin-top: 0vh;
    margin-bottom: 0vh;
    padding-top: 0vh;
    padding-bottom: 0vh;
    font-style: italic;
    padding-left: 50vh;
    padding-right: 50vh;
    line-height: 4vh;
  }
}
