.NavBar {
  background-color: #faf9f6;

  min-height: 7vh;
  min-width: 0vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 10px;
  text-decoration: none;
  font-size: calc(1.5px + 3vmin);
  font-family: didot, serif;
  color: #4f77b4;
}
.NavBar-text {
  background-color: #faf9f6;
  min-height: 5vh;
  min-width: 0vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-decoration: none;
  font-size: calc(1px + 1.7vmin);
  font-family: didot, serif;
  color: #4f77b4;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

/* @media screen and (max-width: 389px) {
  .NavBar {
    background-color: #faf9f6;
    min-height: 7vh;
    min-width: 0vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: left;
    text-decoration: none;
    font-size: calc(1.5px + 2.5vmin);
    font-family: didot, serif;
    color: #4f77b4;
  }
} */

@media screen and (min-width: 1025px) {
  .NavBar {
    background-color: #faf9f6;
    min-height: 7vh;
    min-width: 0vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 170px;
    margin-right: 170px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 10px;
    text-decoration: none;
    /* font-size: calc(1px + 1.5vmin); */
    font-family: didot, serif;
    color: #4f77b4;
  }
  .NavBar-text {
    background-color: #faf9f6;
    min-height: 5vh;
    min-width: 0vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-decoration: none;
    font-size: calc(1px + 1.7vmin);
    font-family: didot, serif;
    color: #4f77b4;
    font-weight: bold;
  }
}
