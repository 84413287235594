.OurStory {
  text-align: center;
  background-color: #faf9f6;
}

.body_style {
  display: block;
  /* position: relative; */
  /* position: absolute; */
  /* unicode-bidi: isolate; */
  margin: 0px auto;
  max-width: 1400px;
  width: auto;
  color: #6082b6;
  /* min-height: 3vh; */
  font-family: didot, serif;
  font-size: calc(2px + 2vmin);
  background-color: #faf9f6;
  font-weight: bold;
  /* margin-top: 5vh;
  margin-bottom: 5vh;
  padding-top: 1vh;
  padding-bottom: 0vh; */
}

.Photo-h1 {
  /* margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  margin-left: 5px; */
  /* padding: 15px; */
  /* margin-right: 5px; */
  /* width: 200px; */
  /* max-width: 200px;  
  padding: 15px;
  width: auto;
  height: auto; */
  /* width: auto; */
}

.Photo_text_h1 {
  color: #6082b6;
  /* min-height: 3vh; */
  font-family: didot, serif;
  font-size: calc(3px + 2.5vmin);
  background-color: #faf9f6;
  font-weight: bold;
  margin-top: 2vh;
  margin-bottom: 5vh;
  padding-top: 1vh;
  padding-bottom: 0vh;
  /* font-style: italic; */
}

.Photo_text2_h1 {
  color: #6082b6;
  /* min-height: 3vh; */
  font-family: didot, serif;
  font-size: calc(3px + 2.5vmin);
  background-color: #faf9f6;
  font-weight: bold;
  margin-top: 5vh;
  margin-bottom: 5vh;
  padding-top: 1vh;
  padding-bottom: 0vh;
  /* font-style: italic; */
}

.Photo-h2 {
  /* margin-right: 1vh;
  margin-bottom: 0vh; */
  /* margin: 0px auto; */
  /* width: 200px;
  max-width: 200px; */
  /* padding: 2px; */
  padding: 2px;

  /* width: auto;
  height: auto; */
}

@media screen and (max-width: 800px) {
  .Photo-h2 {
    /* margin-right: 1vh;
    margin-bottom: 0vh; */
    /* margin: 0px auto; */
    /* width: 200px;
    max-width: 200px; */
    /* padding: 2px; */
    padding: 2px;
    width: 80%;
    /* width: auto;
    height: auto; */
  }
}
