.Password {
  background-color: #faf9f6;
  height: 100vh;
  text-align: center;
}

.Password_h1 {
  color: #6082b6;
  /* height: 10vh; */
  font-family: didot, serif;
  font-size: calc(3px + 2.5vmin);
  padding-top: 25vh;
  padding-bottom: 2vh;
  /* font-style: italic; */
  line-height: 5vh;
}

.Password_h2 {
  color: #6082b6;
  /* height: 10vh; */
  font-family: didot, serif;
  font-size: calc(2px + 1.8vmin);
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-left: 2vh;
  padding-right: 2vh;
  font-style: italic;
}

.error_h2 {
  text-align: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
  margin-left: 5vh;
  margin-right: 5vh;
  align-items: center;
}
